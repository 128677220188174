import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { IconsService } from '@atsdart/common/core/services/icons.service';

/** Root component. */
@Component({
  selector: 'atsdartw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  public constructor() {
    const iconsService = inject(IconsService);

    iconsService.registerIcons();
  }
}
