import { enableProdMode, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideToastr } from 'ngx-toastr';

import { RefreshTokenInterceptor } from '@atsdart/common/core/interceptors/refresh-token-interceptor';
import { AuthInterceptor } from '@atsdart/common/core/interceptors/auth-interceptor';
import { AppConfig } from '@atsdart/common/core/services/app.config';
import { materialProviders } from '@atsdart/common/shared/material-providers';

import { WebAppConfig } from './app/features/shared/web-app.config';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';

const httpInterceptorProviders = [
  // The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    ...httpInterceptorProviders,
    ...materialProviders,
    { provide: AppConfig, useClass: WebAppConfig },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(routes),
    provideToastr(),
  ],
})
  .catch(err => console.error(err));
