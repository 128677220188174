import { Injectable } from '@angular/core';

import { AppConfig } from '@atsdart/common/core/services/app.config';
import { environment } from 'projects/web/src/environments/environment';

/** App-specific implementation of app config. */
@Injectable()
export class WebAppConfig extends AppConfig {
  /** @inheritdoc */
  public readonly apiUrl = new URL('/api/', environment.apiUrl).toString();

  /** @inheritdoc */
  public readonly oauthUrl = new URL('/oauth/authorize/', environment.apiUrl).toString();
}
